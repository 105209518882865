const adminStore = ({
    namespaced: true,
    getters: {},
    mutations: {},
    actions: {
        async purgeOldWebhookLeads({commit, state, rootState, rootGetters}: {commit:any, state:any, rootState:any, rootGetters:any}) {
            return await rootGetters.facebookMarketingFactory.PurgeOldWebhookLeads()
                .then((response: any) => {
                    return response;
                })
        },
        async queueMarketingReport({commit, state, rootState, rootGetters}: {commit:any, state:any, rootState:any, rootGetters:any}) {
            return await rootGetters.facebookMarketingFactory.QueueMarketingReport()
                .then((response: any) => {
                    return response;
                })
        },
        async queueListCastReport({commit, state, rootState, rootGetters}: {commit:any, state:any, rootState:any, rootGetters:any}) {
            return await rootGetters.facebookMarketingFactory.QueueListCastReport()
                .then((response: any) => {
                    return response;
                })
        },
        async queuePdqReport({commit, state, rootState, rootGetters}: {commit:any, state:any, rootState:any, rootGetters:any}) {
            return await rootGetters.facebookMarketingFactory.QueuePdqReport()
                .then((response: any) => {
                    return response;
                })
        }
    }
})

export default adminStore;