<template>
     <div class="component-container">
        <div style="max-width: 50rem;">
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Initiate Reporting Lambdas
                </div>
                <div class="card-body">
                    <div class="form-row text-center">
                        <button class="btn btn-outline-primary ms-2" @click="requestQueue('marketing')" type="button" id="button-addon1">Initiate Marketing Data Report</button>
                        <button class="btn btn-outline-primary ms-2" @click="requestQueue('listcast')" type="button" id="button-addon1">Initiate ListCast Data Report</button>
                        <button class="btn btn-outline-primary ms-2" @click="requestQueue('pdq')" type="button" id="button-addon1">Initiate PDQ Report</button>
                    </div>
                </div>
            </div>
            <div class="row m-3">
                {{ responseMessage }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'QueueLambda'
})
export default class QueueLambda extends Vue {
    responseMessage: string = "";

    async created() {

    }   
    async requestQueue(reportType : string) {
        let actionName = ``;
        if(reportType === 'marketing') {
            actionName = 'admin/queueMarketingReport';
        }
        else if(reportType === 'listcast')
        {
            actionName = 'admin/queueListCastReport';
        }
        else if(reportType === 'pdq')
        {
            actionName = 'admin/queuePdqReport';
        }


        if(actionName !== '')
        {
            await this.$store.dispatch(actionName)
                .then((response : any) => {
                    this.responseMessage = `Successfully queued ${reportType} report.`;
                })
                .catch((error : any) => {
                    this.responseMessage = `Failed to initiate report`;
                });
        }
    }
}
</script>