var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("div", { staticStyle: { "max-width": "50rem" } }, [
      _c("div", { staticClass: "card mb-3 mt-3" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n                Initiate Reporting Lambdas\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-row text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ms-2",
                attrs: { type: "button", id: "button-addon1" },
                on: {
                  click: function ($event) {
                    return _vm.requestQueue("marketing")
                  },
                },
              },
              [_vm._v("Initiate Marketing Data Report")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ms-2",
                attrs: { type: "button", id: "button-addon1" },
                on: {
                  click: function ($event) {
                    return _vm.requestQueue("listcast")
                  },
                },
              },
              [_vm._v("Initiate ListCast Data Report")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary ms-2",
                attrs: { type: "button", id: "button-addon1" },
                on: {
                  click: function ($event) {
                    return _vm.requestQueue("pdq")
                  },
                },
              },
              [_vm._v("Initiate PDQ Report")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row m-3" }, [
        _vm._v("\n            " + _vm._s(_vm.responseMessage) + "\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }